export interface ICartItem {
    event: {
        title: string;
        _key: string;
        slug: string;
        _id: string;
    };
    eventDate: {
        from: string;
        to: string;
    };
    venue: {
        name: string;
        city: string;
    };
    module: {
        _key: string;
        price: number;
        price_with_vat: number;
        name: string;
        _id: string;
        eso9: {
            stock_card: string;
        };
    };
    item: {
        quantity: number;
    };
    online: boolean;
    package: {
        online: boolean;
        presence: boolean;
        date_from: string;
        date_to: string;
    };
}

export enum PaymentStatusTypes {
    SUCCESS = '0',
    FAILED = '1',
    LATER = '2',
}

export enum DiscountApplyStatus {
    OK = 0,
    INVALID_USER = 1,
    CODE_USED = 2,
    INVALID_DATERANGE = 3,
    LOW_PRICE = 4,
    NOT_FOUND = 5,
    NO_DISCOUNT = 6,
}

export enum DiscountCodeType {
    PERCENTAGE = 0,
    EURO = 1,
}

export enum DiscountApplyType {
    ALL = 0,
    SINGLE = 1,
}
